/*
|---------------------------------------------------------------------
| CODE Vuex Getters
|---------------------------------------------------------------------
*/
export default {
	isCodes(state) {
		// return codes.filter((t) => !t.completed)
		return state.codes;
	},
};
