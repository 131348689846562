export default {
	SET_ALERT_DIALOG_VISIBLE(state, visible) {
		state.alertDialog.visible = visible;
	},

	SET_ALERT_DIALOG_TITLE(state, title) {
		state.alertDialog.title = title;
	},

	SET_ALERT_DIALOG_CONTENTS(state, contents) {
		state.alertDialog.contents = contents;
	},

	SET_SIGNIN_DIALOG_VISIBLE(state, visible) {
		state.signInDialog.visible = visible;
	},
	SET_CATEGORY_DIALOG_VISIBLE(state, visible) {
		state.categoryDialog.visible = visible;
	},
	SET_PRODUCTDETAIL_DIALOG_VISIBLE(state, visible) {
		state.productDetailDialog.visible = visible;
	},
	SET_CARTMODIFY_DIALOG_VISIBLE(state, visible) {
		state.cartModifyDialog.visible = visible;
	},
	SET_PASSWORD_DIALOG_VISIBLE(state, visible) {
		state.passwordDialog.visible = visible;
	},
};
