/*
|---------------------------------------------------------------------
| TODO Vuex Mutations
|---------------------------------------------------------------------
*/
export default {
	SET_DATA: (state, data) => {
		// console.log('Mutations에 넘어온 Data', data)
		state.codes = data;
	},
	ADD_CODE: (state, data) => {
		console.log('mutation에 내려온 데이타 ', data);
		// state.codes.push({
		//   id: '_' + Math.random().toString(36).substr(2, 9),
		//   ...code,
		//   completed: false
		// })
		state.codes.push(data);
	},
	UPDATE_CODE: (state, code) => {
		const codeIdx = state.codes.find(t => t.id === code.id);

		Object.assign(codeIdx, code);
	},
	ACTIVE_CODE: (state, data) => {
		const codeIdx = state.codes.findIndex(t => t.id === data.id);

		state.codes[codeIdx].active = data.active;
	},
	POSITIVE_CODE: (state, data) => {
		const codeIdx = state.codes.findIndex(t => t.id === data.id);

		state.codes[codeIdx].active = data.active;
	},
	DELETE_CODE: (state, code) => {
		const codeIdx = state.codes.findIndex(t => t.id === code.id);

		if (codeIdx !== -1) state.codes.splice(codeIdx, 1);
	},
};
