export default {
	// 카트 목록
	isCart(state) {
		return state.carts;
	},
	isModifyCart(state) {
		return state.modifyCart;
	},
	isTotalPages(state) {
		return state.totalPages;
	},
	isPageSize(state) {
		return state.setPageSize;
	},
	// 주문할 장바구니 아이디
	isOrderCartItem(state) {
		return state.orderCartItems;
	},
};
