//
//
//
//
//
//
//
//
//
//

export default {
	data() {
		return {
			hidden: false,
		};
	},
	mounted() {
		this.scroll(); // 마운트에서 아래 method 호출해줘야 수치가 반영되더라.
	},
	methods: {
		toTop() {
			// console.log('1111111')
			// this.$router.push({hash:""})
			this.$vuetify.goTo(0);
		},
		scroll() {
			//  이걸 computed 값으로 처리도 해 보고 - window is not defined 나옴. method로 넣어야 작동
			window.onscroll = () => {
				if (window.pageYOffset >= 300) this.hidden = false;
				else this.hidden = true;
			};
		},
	},
};
