export default {
	isAlertDialogVisible(state) {
		return state.alertDialog.visible;
	},
	isAlertDialogTitle(state) {
		return state.alertDialog.title;
	},
	isAlertDialogContents(state) {
		return state.alertDialog.contents;
	},

	isCategoryDialogVisible(state) {
		return state.categoryDialog.visible;
	},
	isSignInDialogVisible(state) {
		return state.signInDialog.visible;
	},
	isPasswordDialogVisible(state) {
		return state.passwordDialog.visible;
	},
};
