/*
|---------------------------------------------------------------------
| CATEGORY Vuex Getters
|---------------------------------------------------------------------
*/
export default {
	onCategory({ categories }) {
		// console.log('active >> ', codes)

		// return codes.filter((t) => !t.completed)
		return categories.filter(t => t.active === 'Y');
	},
	offCategory({ categories }) {
		return categories.filter(t => t.active === 'N');
	},
	modalCategory({ categoryModal }) {
		return categoryModal;
	},

	// 도매상 카테고리 만 return
	partnerCategory({ partnerCategory }) {
		return partnerCategory;
	},
	// Tree 필요한 카테고리 data(Tree) return
	treeCategory({ categoryTree }) {
		return categoryTree;
	},
	// 리스트에 필요한 카테고리 return
	listCategory({ categories }) {
		return categories;
	},

	treeLevel1({ categoryTree }) {
		const treeLevel1 = categoryTree.subCategories ? categoryTree.subCategories.length : 0;

		return treeLevel1;
	},
};
