export default {
	// Cart 목록 Actions
	async getSearchCart({ commit }, param) {
		try {
			const { data } = await this.$cartApi.fetchSearchCarts(param);
			commit('SET_DATA', data);
		} catch (error) {}
	},

	// Cart 등록
	async addCart({ commit }, param) {
		try {
			const { customerUser, itemId, totalPrice, itemList } = param;
			const requestData = {
				customerUser,
				itemId,
				totalPrice,
				itemList,
			};
			const { data } = await this.$cartApi.registerCartByBody(requestData);
			commit('ADD_CART', data);
		} catch (error) {}
	},
	// Cart 수정(수량, 옵션 추가)
	async updateCart({ commit }, param) {
		try {
			const { id, itemId, totalPrice, itemList } = param;
			const requestData = {
				id,
				itemId,
				totalPrice,
				itemList,
			};
			await this.$cartApi.updateCartByBody(requestData);
		} catch (error) {}
	},

	// Cart N개 삭제
	async deleteCartList({ commit }, params) {
		try {
			await this.$cartApi.deleteCartListByBody(params);
			commit('DELETE_CART_LIST', params);
		} catch (error) {}
	},
	// Cart 삭제
	async deleteCart({ commit }, param) {
		try {
			const requestData = {
				id: param.cartId,
			};
			await this.$cartApi.deleteCartByBody(requestData);
			commit('DELETE_CART', param);
		} catch (error) {}
	},
	// Cart 아이템 삭제
	async deleteCartItem({ commit }, param) {
		try {
			const requestData = {
				id: param.cartItemId,
			};
			await this.$cartApi.deleteCartItemByBody(requestData);
			commit('DELETE_CARTITEM', param);
		} catch (error) {}
	},
};
