import getters from './getters';
import mutations from './mutations';
import actions from './actions';

/*
|---------------------------------------------------------------------
| CODE Vuex Store
|---------------------------------------------------------------------
*/

const state = () => ({
	// codes,
	codes: [],

	// labels
	labels: [
		{
			id: 'CODE_MALL',
			title: '상가 코드',
			color: 'orange',
		},
		{
			id: 'CODE_BANK',
			title: '은행 코드',
			color: 'blue',
		},
		{
			id: 'CODE_COLOR',
			title: '색상 코드',
			color: 'orange',
		},
		{
			id: 'CODE_SIZE',
			title: '사이즈 코드',
			color: 'blue',
		},
	],
});

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
