//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapMutations, mapActions } from 'vuex';
import { csMenu as menu } from './menus.js';
export default {
	data() {
		return {
			drawer: null,
			menu,
			clickedMenu: '',
			selectedItem: 0,
		};
	},
	head() {
		return {
			title: this.$store.getters['mall/isMallTitle'],
		};
	},
	computed: {
		...mapGetters('auth', ['isLoggedIn']),
		...mapGetters('app', ['isAlertDialogVisible', 'isSignInDialogVisible']),
		...mapGetters('mall', ['isPartnerMallInfoData', 'isLogoImage']),
	},
	mounted() {
		const mKey = Object.keys(menu).filter(key => menu[key].link === this.$route.path);
		if (mKey.length > 0) {
			// this.changeColor(menu[mKey].title, this.$route.path);
			this.changeColor(menu[mKey].title);
		}
	},
	methods: {
		...mapMutations('auth', ['LOGOUT']),
		...mapMutations('app', ['SET_ALERT_DIALOG_VISIBLE', 'SET_SIGNIN_DIALOG_VISIBLE']),
		...mapActions('code', ['getAllCodes']),
		changeColor(value, link) {
			this.clickedMenu = value;
		},
		mypage() {
			this.$router.push('/app/OrderPage');
		},
		onAlertDialog() {
			this.SET_ALERT_DIALOG_VISIBLE(true);
		},
		onSignInDialog() {
			this.SET_SIGNIN_DIALOG_VISIBLE(true);
		},
		async callAllCodes() {
			try {
				const response = await this.getAllCodes();
				console.log(response);
			} catch (error) {
				console.log('error ? ', error);
			}
		},
		goHome() {
			this.$router.push('/');
			this.changeColor('홈');
		},
	},
};
