//
//
//
//
//
//
//
//
//
//
//
//

// import DefaultToolbar from '../toolbar/DefaultToolbar.vue';
import AppToolbar from '../toolbar/AppToolbar.vue';

export default {
	components: {
		// DefaultToolbar,
		AppToolbar,
	},
};
