import getters from './getters';
import mutations from './mutations';
import actions from './actions';

/*
|---------------------------------------------------------------------
| CATEGORY Vuex Store
|---------------------------------------------------------------------
*/

const state = () => ({
	categoryModal: {},
	categoryTree: {},
	categories: [],
	partnerCategory: [],
	// labels
	labels: [
		{
			id: 'CATEGORY_TREE',
			title: '카테고리 팝업',
			color: 'orange',
		},
		// {
		//   id: 'CODE_BANK',
		//   title: '은행 코드',
		//   color: 'blue'
		// }
	],
});

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
