export const order = axios => ({
	// Order 조회
	fetchSearchOrders(params) {
		return axios.get('/api/retailer/orders/search', { params });
	},
	// Order 저장
	registerOrderByBody(params) {
		return axios.post('/api/retailer/orders', params);
	},
	// Order 취소
	deleteOrderByBody(params) {
		return axios.post('/api/retailer/orders/status', params);
	},

	// 임시 대량 등록
	registerBulkByBody(params) {
		return axios.post('/api/retailer/bulks', params);
	},

	// 대량 조회
	fetchSearchBulks() {
		return axios.get('/api/retailer/bulks', '');
	},

	// Order 상세보기
	fetchOrdersById(id) {
		return axios.get(`/api/retailer/orders/${id}`);
	},
});
