import actions from './actions';
import mutations from './mutations';
import getters from './getters';

/*
|---------------------------------------------------------------------
| App Vuex Store
|---------------------------------------------------------------------
*/

const state = () => ({
	alertDialog: {
		visible: false,
		title: '',
		contents: '',
	},
	signInDialog: {
		visible: false,
	},
	categoryDialog: {
		visible: false,
	},
	productDetailDialog: {
		visible: false,
	},
	cartModifyDialog: {
		visible: false,
	},
	passwordDialog: {
		visible: false,
	},
});

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters,
};
