export default {
	// 쇼핑몰 푸터, 전체 정보
	isPartnerMallInfoData(state) {
		return state.partnerMallInfo;
	},
	// 쇼핑몰 타이틀
	isMallTitle(state) {
		return state.partnerMallInfo.mallTitle;
	},
	// 쇼핑몰 로고
	isLogoImage(state) {
		const logoItem = state.partnerMallInfo.mallLogoList;
		if (logoItem.length > 0) {
			return logoItem[0].url;
		} else {
			return '/images/sample.png';
		}
	},
	// 메인 히어로
	isHeros(state) {
		const heros = state.partnerMallInfo.mallHeroList;
		if (heros.length > 0) {
			return heros;
		} else {
			return null;
		}
	},
	// 메인 배너
	isBanners(state) {
		const banners = state.partnerMallInfo.mallBannerList;
		if (banners.length > 0) {
			return banners;
		} else {
			return null;
		}
	},
	// 쇼핑몰 푸터, 전체 정보
	// 찜한 상품
	isPickItems(state) {
		return state.productsPick;
	},
	isTotalPagesPick(state) {
		return state.totalPagesPick;
	},
	isTotalItemsPick(state) {
		return state.totalItemsPick;
	},
	isPagePick(state) {
		return state.pagePick;
	},
	isPageSizePick(state) {
		return state.setPageSizePick;
	},
	// 단골 매장
	isRegularStores(state) {
		return state.regularStores;
	},
	isTotalPagesRegular(state) {
		return state.totalPagesRegular;
	},
	isTotalItemsRegular(state) {
		return state.totalItemsRegular;
	},
	isPageRegular(state) {
		return state.pageRegular;
	},
	isPageSizeRegular(state) {
		return state.setPageSizeRegular;
	},

	// 상가별 도매상
	isMallStreet(state) {
		return state.mallStreet;
	},
	isStreetSearchShops(state) {
		return state.streetSearchShops;
	},
};
