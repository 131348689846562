import {
	saveAuthToCookie,
	saveUserToCookie,
	saveUserCommissionRateToCookie,
	saveUserDeliveryChargeToCookie,
} from '@/utils/cookies.js';

// 로그인 action => mutations에 전달

export default {
	// 로그인
	async LOGIN({ commit }, payload) {
		const response = await this.$authApi.loginUser(payload);
		// console.log(response.data.username);
		commit('SET_USER', response.data.username);
		commit('SET_TOKEN', response.data.token);
		commit('SET_USERNAME', response.data.username);
		saveUserToCookie(response.data.username);
		saveAuthToCookie(response.data.token);

		return response;
	},

	// 회원가입
	async SIGNUP({ commit }, payload) {
		const response = await this.$authApi.signupCustomerUser(payload);
		return response;
	},

	// 회원정보 가져오기
	async getCustomerShop({ commit }, param) {
		try {
			const { data } = await this.$authApi.fetchCustomerShop(param);
			commit('SET_CUSTOMERSHOP_DATA', data);

			// 수수료율, 택배비
			commit('SET_COMMISSIONRATE', data.buyingCommissionRate);
			commit('SET_DELIVERYCHARGE', data.outDeliveryCharge);
			saveUserCommissionRateToCookie(data.buyingCommissionRate);
			saveUserDeliveryChargeToCookie(data.outDeliveryCharge);
		} catch (error) {}
	},
	// 회원정보 수정
	async updateCustomerShop({ commit }, param) {
		// console.log('authApi updateCustomerShop(actions)==', param);
		const response = await this.$authApi.updateCustomerShopByBody(param);
		return response;
	},
	// 비밀번호 변경
	async updatePassword({ commit }, param) {
		// console.log('authApi updatePassword(actions)==', param);
		const response = await this.$authApi.updatePasswordByBody(param);
		return response;
	},
	// 파일 업로드
	async registerCustomerFile({ commit }, param) {
		try {
			await this.$authApi.registerCustomerFileByBody(param);
		} catch (error) {}
	},

	// async REFRESH_TOKEN({ commit }, data) {
	// 	const response = await refreshToken(data);

	// 	// console.log(response)
	// 	commit('SET_USER', response.data.username);
	// 	commit('SET_TOKEN', 'Bearer ' + response.data.token);
	// 	saveUserToCookie(response.data.username);
	// 	saveAuthToCookie('Bearer ' + response.data.token);

	// 	return response;
	// },
};
