//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations } from 'vuex';
import CartModifyChild from '../order/CartModifyChild.vue';
export default {
	components: { CartModifyChild },
	data() {
		return {
			offsetTop: 0,
			hidden: true,
		};
	},
	methods: {
		...mapMutations('app', ['SET_CARTMODIFY_DIALOG_VISIBLE']),

		close() {
			this.SET_CARTMODIFY_DIALOG_VISIBLE(false);
		},
	},
};
