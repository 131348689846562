/*
|---------------------------------------------------------------------
| PRODUCT Vuex Getters
|---------------------------------------------------------------------
*/
export default {
	// 검색 Trigger
	getWatchCount({ watchCount }) {
		return watchCount;
	},
	// 상품 목록
	isProduct(state) {
		// return products.filter(t => t.active === 'Y');
		return state.products;
	},
	// 상품 상세
	isProductDetail(state) {
		return state.productDetail;
	},
	// 상품 상세 옵션 리스트
	isProductDetailOption(state) {
		return state.productDetailOption;
	},
	// 상품 상세 주문 수량
	isProductOrderCount(state) {
		return state.productOrderCount;
	},
	// 상품 상세 주문 금액
	isProductOrderSum(state) {
		return state.productOrderSum;
	},

	isPickItemBoolean(state) {
		return state.pickitemBoolean;
	},
	isRegularBoolean(state) {
		return state.regularBoolean;
	},
	isRegularStore(state) {
		return state.regularStore;
	},
	// 상품 카테고리
	isProductCategory(state) {
		return state.productCategory;
	},

	// 검색조건
	isSearchCondition(state) {
		return state.searchCondition;
	},
	isStock(state) {
		return state.searchCondition.stock;
	},
	isCategoryPath(state) {
		return state.searchCondition.categoryPath;
	},
	isTotalPages(state) {
		return state.totalPages;
	},
	isTotalItems(state) {
		return state.totalItems;
	},
	isPage(state) {
		return state.page;
	},
	isPageSize(state) {
		return state.setPageSize;
	},
};
