//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import { shopMenu as menu } from './menus.js';

export default {
	data() {
		return {
			drawer: null,
			menu,
		};
	},
	head() {
		return {
			title: this.$store.getters['mall/isMallTitle'],
		};
	},
	computed: {
		...mapGetters('mall', ['isPartnerMallInfoData', 'isLogoImage']),
	},
	methods: {
		goHome() {
			this.$router.push('/');
		},
	},
};
