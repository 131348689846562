import actions from './actions';
import mutations from './mutations';
import getters from './getters';

/*
|---------------------------------------------------------------------
| Auth Vuex Store
|---------------------------------------------------------------------
*/

const state = () => ({
	user: {},
	token: '',
	username: '',
	termsAgree: false,
	policyAgree: false,
	confirmAgree: false,
	isRegistrated: false,
	customerShop: {},
	commissionRate: 0,
	deliveryCharge: 0,
});

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters,
};
