export default {
	// 목록 저장
	SET_DATA: (state, data) => {
		// console.log('server 에서 내려온 데이타(mutation) >> ', data)
		// console.log('==SET_DATA(mutations)=>', data);
		// ES6 ... spread operator (전개연산자) : 배열의 원소들을 분해해서 개별요소 리턴
		state.posts = data.data;
		state.page = data.page.currentPage;
		state.totalPages = data.page.totalPages;
		state.totalItems = data.page.totalItems;
	},
	SET_DETAIL_DATA: (state, data) => {
		// console.log('==SET_DETAIL_DATA(mutations)=>', data);
		state.postDetail = data;
	},
	SET_DATA_COMMENT: (state, data) => {
		// console.log('==SET_DATA_COMMENT(mutations)=>', data);
		state.postsComment = data;
	},

	SET_POSTCLASS: (state, data) => {
		state.selectedPostClass = data;
	},
	SET_PAGE_INIT: (state, data) => {
		state.page = 0;
		state.setPageSize = 10;
		state.totalPages = 0;
		state.totalItems = 0;
		state.searchCondition.searchColumn = '제목';
		state.searchCondition.searchText = '';
	},

	SET_VIEW_ITEM_ID: (state, data) => {
		state.viewItemId = data;
	},

	SET_SEARCHCONDITION: (state, data) => {
		state.searchCondition.searchColumn = data.column;
		state.searchCondition.searchText = data.text;
	},
};
