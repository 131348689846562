//
//
//
//
//
//
//
//
//
//
//

import DefaultToolbar from '../toolbar/DefaultToolbar.vue';

export default {
	components: {
		DefaultToolbar,
	},
};
