//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SignInDialog from '@/components/ui/landing/signin/SignInDialog.vue';
import CartModifyDialog from '@/components/ui/landing/dialog/CartModifyDialog.vue';
import CategoryDialog from '@/components/ui/landing/dialog/CategoryDialog.vue';
import ProductDetailDialog from '@/components/ui/landing/dialog/ProductDetailDialog.vue';
import AlertDialog from '@/components/ui/landing/dialog/AlertDialog.vue';
import DefaultHeader from '@/components/ui/landing/header/DefaultHeader.vue';
import FabButton from '@/components/util/FabButton';
import DefaultFooter from '@/components/ui/landing/footer/DefaultFooter.vue';

export default {
	components: {
		DefaultHeader,
		FabButton,
		AlertDialog,
		DefaultFooter,
		SignInDialog,
		CategoryDialog,
		ProductDetailDialog,
		CartModifyDialog,
	},
	async mounted() {
		// 도매상 정보 가져오기
		const mallId = process.env.partnerMallId;
		await this.$store.dispatch('mall/getPartnerMallById', mallId);
	},
};
