import getters from './getters';
import mutations from './mutations';
import actions from './actions';

const state = () => ({
	posts: [],
	postDetailId: 0,
	postDetail: {},
	postsComment: [],
	page: 0,
	setPageSize: 10,
	totalPages: 0,
	totalItems: 0,
	searchCondition: {
		searchColumn: '',
		searchText: '',
	},
	viewItemId: 0,
	selectedPostClass: '공지',
	tabs: [
		{ id: 0, name: '공지', title: '공지&이벤트', enable: 'Y' },
		{ id: 1, name: 'QA', title: 'Q&A', enable: 'Y' },
		{ id: 2, name: '도매회원인증', title: '도매회원인증', enable: 'Y' },
		{
			id: 3,
			name: '세금계산서신청',
			title: '세금계산서신청',
			enable: 'Y',
		},
		{
			id: 4,
			name: '입점신청',
			title: '입점신청 및 업무제휴',
			enable: 'Y',
		},
	],
});

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
