import getters from './getters';
import mutations from './mutations';
import actions from './actions';

const state = () => ({
	orders: [],
	page: 0,
	setPageSize: 10,
	totalPages: 0,
	totalItems: 0,
});

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
