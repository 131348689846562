//
//
//
//
//
//
//
//
//
//
//

import AuthToolbar from '../toolbar/AuthToolbar.vue';

export default {
	components: {
		AuthToolbar,
	},
};
