//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from 'axios';
import { mapGetters } from 'vuex';
export default {
	data() {
		return {
			prDialog: false,
			conDialog: false,
			termsText: '',
			privacyText: '',
		};
	},
	computed: {
		...mapGetters('mall', ['isPartnerMallInfoData']),
	},
	mounted() {
		this.termsData();
		this.privacyData();
	},
	methods: {
		async termsData() {
			const { data } = await axios.get('/terms.txt');
			this.termsText = data;
		},
		async privacyData() {
			const { data } = await axios.get('/privacy.txt');
			this.privacyText = data;
		},
		privacy() {
			this.prDialog = true;
		},
		condition() {
			this.conDialog = true;
		},
		formatNewline(val) {
			return val.replace(/<br>/g, '\n');
		},
	},
};
