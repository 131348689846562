export default {
	// 주문내역
	isOrder(state) {
		return state.orders;
	},
	isTotalPages(state) {
		return state.totalPages;
	},
	isPageSize(state) {
		return state.setPageSize;
	},
};
