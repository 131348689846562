//
//
//
//
//
//
//
//
//
//
//
//

import DefaultFooter from '@/components/ui/landing/footer/DefaultFooter.vue';
import AuthHeader from '@/components/ui/landing/header/AuthHeader.vue';
import AlertDialog from '@/components/ui/landing/dialog/AlertDialog.vue';
// import Header1 from '@/components/ui/landing/header/Header1';

export default {
	components: {
		DefaultFooter,
		AuthHeader,
		AlertDialog,
	},
	async mounted() {
		// 도매상 정보 가져오기
		const mallId = process.env.partnerMallId;
		await this.$store.dispatch('mall/getPartnerMallById', mallId);
	},
};
