//
//
//
//
//
//
//
//
//

import AuthHeader from '@/components/ui/landing/header/AuthHeader.vue';
import FabButton from '@/components/util/FabButton';

export default {
	components: {
		AuthHeader,
		FabButton,
	},
};
