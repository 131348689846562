//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations } from 'vuex';
export default {
	data() {
		return {
			// dialog3: true,
			notifications: false,
		};
	},
	methods: {
		...mapMutations('app', ['SET_ALERT_DIALOG_VISIBLE']),
		inVisibleAlertDialog() {
			this.SET_ALERT_DIALOG_VISIBLE(false);
		},
	},
};
