export default {
	// 새글 목록
	isPosts(state) {
		return state.posts;
	},
	isModifyPosts(state) {
		return state.modifyPosts;
	},
	isTotalPages(state) {
		return state.totalPages;
	},
	isPage(state) {
		return state.page;
	},
	isPageSize(state) {
		return state.setPageSize;
	},
	isTotalItems(state) {
		return state.totalItems;
	},
	// post 수정 데이터
	isPostDetailData(state) {
		return state.postDetail;
	},
	// post 댓글 목록
	isPostsComment(state) {
		return state.postsComment;
	},
	// 검색 조건
	isSearchCondition(state) {
		return state.searchCondition;
	},
	// 선택된 게시글 ID
	isViewItemId(state) {
		return state.viewItemId;
	},
	// post class
	isSelectedPostClass(state) {
		return state.selectedPostClass;
	},
	// post tabs
	isTabs(state) {
		return state.tabs;
	},
};
