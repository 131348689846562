export default {
	// Cart 목록
	SET_DATA: (state, data) => {
		// console.log('server 에서 내려온 데이타(mutation) >> ', data)
		console.log('==SET_DATA(mutations)=>', data);
		// ES6 ... spread operator (전개연산자) : 배열의 원소들을 분해해서 개별요소 리턴
		state.carts = data.data;
		state.page = data.page.currentPage;
		state.totalPages = data.page.totalPages;
		state.totalItems = data.page.totalItems;
	},

	// Cart 등록
	ADD_CART: (state, data) => {
		state.carts.push(data);
	},
	// Cart 수정 완료
	UPDATE_CART: (state, data) => {
		state.modifyCart = data;
	},
	// 카트 수정 값
	SET_MODIFYCART: (state, data) => {
		// console.log('==SET_MODIFYCART(mutations)=>', data);
		// state.modifyCart = data; // 얕은 보갓
		state.modifyCart = JSON.parse(JSON.stringify(data));
	},
	UPDATE_MODIFYCART: (state, data) => {
		state.modifyCart.cartItems[data.idx].itemCount = data.count;

		let sum = 0;
		for (let inx = 0; inx < state.modifyCart.cartItems.length; inx++) {
			const aItem = state.modifyCart.cartItems[inx];
			sum += parseInt(aItem.itemPrice) * parseInt(aItem.itemCount);
		}
		state.modifyCart.totalPrice = sum;
	},
	ADD_MODIFYCART: (state, data) => {
		console.log('ADD_MODIFYCART-push', data);
		state.modifyCart.cartItems.push(data);

		let sum = 0;
		for (let inx = 0; inx < state.modifyCart.cartItems.length; inx++) {
			const aItem = state.modifyCart.cartItems[inx];
			sum += parseInt(aItem.itemPrice) * parseInt(aItem.itemCount);
		}
		state.modifyCart.totalPrice = sum;
	},
	// Cart 삭제
	DELETE_CART_LIST: (state, cartList) => {
		for (let inx = 0; inx < cartList.length; inx++) {
			const idx = state.carts.findIndex(t => t.cartId === cartList[inx].cartId);
			if (idx !== -1) state.carts.splice(idx, 1);
		}
	},

	// Cart 삭제
	DELETE_CART: (state, cart) => {
		const idx = state.carts.findIndex(t => t.cartId === cart.cartId);
		if (idx !== -1) state.carts.splice(idx, 1);
	},

	// Cart 아이템 삭제
	DELETE_CARTITEM: (state, cart) => {
		const idx = state.carts.findIndex(t => t.cartId === cart.cartId);
		const itemidx = state.carts[idx].cartItems.findIndex(t => t.cartItemId === cart.cartItemId);
		if (itemidx !== -1) {
			state.carts[idx].cartItems.splice(itemidx, 1);
		}
	},

	// 주문진행 카트 ID
	SET_ORDER_CARTID: (state, data) => {
		state.orderCartItems = data;
	},
};
