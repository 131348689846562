//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapMutations } from 'vuex';

export default {
	data() {
		return {
			selectedItem: -1,
			search: '',
			selection: [],
			open: [],
			allOpened: false,
			lastOpen: [],
			deleteDialog: false,
			deleteItem: {},
			alertMessage: '',
			parentResult: [],
			selectedCategoryItem: {},
			selectedCategoryPath: '',

			treeData: [],
			options: {
				children: 'children',
				label: 'name',
			},
		};
	},
	computed: {
		...mapGetters('auth', ['isLoggedInCheck']),
		...mapGetters('product', ['isProductCategory']),
		...mapGetters('category', ['treeCategory', 'onCategory', 'partnerCategory']),
	},
	watch: {
		partnerCategory(val) {
			const data = [...this.partnerCategory];
			const sortedData = data.sort((a, b) => {
				const partsA = a.split('/').map(part => parseInt(part, 10));
				const partsB = b.split('/').map(part => parseInt(part, 10));

				for (let i = 0; i < Math.min(partsA.length, partsB.length); i++) {
					if (partsA[i] !== partsB[i]) {
						return partsA[i] - partsB[i];
					}
				}

				return partsA.length - partsB.length;
			});
			// console.log(sortedData);
			if (sortedData.length > 0) {
				const tree = this.buildTreeFromArray(sortedData);
				// this.treeData = tree;
				console.log('tree', tree);
				this.treeData = this.convertTreeToKorean(tree);
				this.open = ['의류'];
			}
		},
		selectedCategoryPath(newValue, oldValue) {
			// console.log(`WATCH : The query changed from ${oldValue} to ${newValue}`);
			if (newValue !== oldValue) {
				const categoryPathObject = {
					categoryPath: this.selectedCategoryItem.categoryPath,
					categoryPathName: this.getCategoryNameFromJson(this.selectedCategoryItem.categoryPath),
				};
				this.$store.commit('product/SET_ALL_INIT_DATA', ''); // 상품 목록 및 페이지, 조건 reset
				this.$store.commit('product/SET_STOCK', '판매');
				// Store 카테고리 값 변경
				this.$store.commit('product/SET_CATEGORYPATH', categoryPathObject);
				// 카테고리별 상품목록 페이지 이동 ==> 상품검색페이지로 이동
				// this.$router.push('/shop/CategoryProducts?query=' + this.selectedCategoryItem.categoryPath);
			}
		},
		treeCategory(val) {
			this.open = this.treeCategory.children.map(item => item.id);
		},
	},

	methods: {
		...mapMutations('app', ['SET_CATEGORY_DIALOG_VISIBLE']),
		...mapMutations('product', ['SET_CATEGORYPATH']),
		handleSearch(val) {
			if (val) {
				if (!this.allOpened) {
					this.lastOpen = this.open;
					this.allOpened = true;
					this.$refs.tree.updateAll(true);
				}
			} else {
				this.$refs.tree.updateAll(false);
				this.allOpened = false;
				this.open = this.lastOpen;
			}
		},
		selectedCategory(item) {
			this.selectedCategoryItem = item;
			this.selectedCategoryPath = item.categoryPath;
			// console.log('selectedCategory====', this.selectedCategoryPath);
			if (this.$route.path !== '/shop/CategoryProducts') {
				const categoryPathObject = {
					categoryPath: this.selectedCategoryItem.categoryPath,
					categoryPathName: this.getCategoryNameFromJson(this.selectedCategoryItem.categoryPath),
				};
				this.$store.commit('product/SET_ALL_INIT_DATA', ''); // 상품 목록 및 페이지, 조건 reset
				this.$store.commit('product/SET_STOCK', '판매');
				// Store 카테고리 값 변경
				this.$store.commit('product/SET_CATEGORYPATH', categoryPathObject);
				// 카테고리별 상품목록 페이지 이동 ==> 상품검색페이지로 이동
				this.$router.push('/shop/CategoryProducts?query=' + this.selectedCategoryItem.categoryPath);
			}

			this.dialog = false;
			this.SET_CATEGORY_DIALOG_VISIBLE(false);
		},
		// 카테고리 코드 => 카테고리 이름
		getCategoryNameFromJson(data) {
			const parseData = data.split('/');
			let tempData = '';

			for (let i = 0; i < parseData.length; i++) {
				let rexData = '';

				if (i !== parseData.length - 1) {
					rexData = ' > ';
				}
				tempData += this.parseCategoryPath(parseData[i]) + rexData;
			}

			return tempData;
		},
		parseCategoryPath(data) {
			// console.log('넘어온 데이타 ', data);
			const findData = this.onCategory.filter(it => String(it.id).includes(data));

			return findData[0].name;
		},
		openTreeModal(prouct) {
			console.log('open!!', prouct);
			// this.dialog = true;
			setTimeout(() => {
				this.open = this.treeCatetory.children.map(item => item.id);
			}, 100);
		},
		close() {
			this.SET_CATEGORY_DIALOG_VISIBLE(false);
		},
		// 배열을 트리로 만들기
		buildTreeFromArray(flatArray) {
			const tree = {};

			flatArray.forEach(path => {
				const levels = path.split('/');
				let currentNode = tree;

				levels.forEach(level => {
					if (!currentNode.children) {
						currentNode.children = [];
					}

					const existingNode = currentNode.children.find(node => node.name === level);

					if (!existingNode) {
						const newNode = {
							name: level,
							children: [],
						};
						currentNode.children.push(newNode);
						currentNode = newNode;
					} else {
						currentNode = existingNode;
					}
				});
			});

			return tree.children;
		},
		// 트리 데이터를 한글로 변환하는 재귀 함수
		convertTreeToKorean(treeData) {
			return treeData.map(node => {
				const matchingItem = this.onCategory.find(item => item.id.toString() === node.name);
				const koreanName = matchingItem ? matchingItem.name : node.name;
				const path = matchingItem ? matchingItem.categoryPath : '222'; // 새로 추가된 categoryPath 속성

				if (node.children && node.children.length > 0) {
					node.children = this.convertTreeToKorean(node.children);
				}
				return {
					...node,
					name: koreanName, // 이름을 한글로 변환
					categoryPath: path,
				};
			});
		},
	},
};
