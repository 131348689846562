import { saveMallOrderYnToCookie } from '@/utils/cookies.js';
export default {
	// 몰 메인정보 가져오기
	async getPartnerMallById({ commit }, id) {
		try {
			const { data } = await this.$mallApi.fetchPartnerMallById(id);
			commit('SET_PARTNER_MALL_DATA', data);
			// 매장 주문 YN
			saveMallOrderYnToCookie(data.orderYn);
		} catch (error) {}
	},

	// 찜한 상품 추가
	async addPickItem({ commit }, param) {
		try {
			const { data } = await this.$mallApi.registerPickItemByBody(param);
			console.log('addPickIte<(actions)', data);
		} catch (error) {}
	},
	// 찜한 상품 삭제
	async deletePickItem({ commit }, id) {
		try {
			await this.$mallApi.deletePickItemByBody(id);
			commit('DELETE_PICKITEM', id);
		} catch (error) {}
	},
	// 찜한 상품 목록
	async getSearchPickItem({ commit }, param) {
		try {
			const { data } = await this.$mallApi.fetchSearchPickItem(param);
			commit('SET_PICKITEM_DATA', data);
		} catch (error) {}
	},
	// 단골 매장 추가
	async addRegularStore({ commit }, param) {
		try {
			// const { data } = await this.$mallApi.registerRegularStoreByBody(param);
			// console.log('addRegularStore(actions)', data);
			await this.$mallApi.registerRegularStoreByBody(param);
		} catch (error) {}
	},
	// 단골 매장 삭제
	async deleteRegularStore({ commit }, id) {
		try {
			await this.$mallApi.deleteRegularStoreByBody(id);
		} catch (error) {}
	},
	// 단골 매장 목록
	async getSearchRegularStore({ commit }, param) {
		try {
			const { data } = await this.$mallApi.fetchSearchRegularStore(param);
			commit('SET_REGULARSTORE_DATA', data);
		} catch (error) {}
	},
	// 상가별 도매상 Shop Modal에서 Api Call
	async getSearchShops({ commit }, param) {
		try {
			const { data } = await this.$mallApi.fetchSearchShops(param);
			commit('SET_SHOP_DATA', data.data);
		} catch (error) {}
	},
};
