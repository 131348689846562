import getters from './getters';
import mutations from './mutations';
import actions from './actions';

const state = () => ({
	carts: [],
	modifyCart: null,
	orderCartItems: [],
	page: 0,
	setPageSize: 100,
	totalPages: 0,
	totalItems: 0,
});

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
