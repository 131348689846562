export default {
	// Order 목록
	SET_DATA: (state, data) => {
		// console.log('server 에서 내려온 데이타(mutation) >> ', data)
		console.log('==SET_DATA(mutations)=>', data);
		// ES6 ... spread operator (전개연산자) : 배열의 원소들을 분해해서 개별요소 리턴
		state.orders = data.data;
		state.page = data.page.currentPage;
		state.totalPages = data.page.totalPages;
		state.totalItems = data.page.totalItems;
	},

	// Order 등록
	ADD_ORDER: (state, data) => {
		state.orders.push(data);
	},
	// Order 취소
	DELETE_ORDER: (state, order) => {
		const idx = state.orders.findIndex(t => t.orderId === order.orderId);
		if (idx !== -1) state.orders.splice(idx, 1);
	},
};
