export const post = axios => ({
	// 글 조회
	fetchSearchPosts(params) {
		return axios.get('/api/retailer/posts/search', { params });
	},
	// 글 Id 상세 조회
	fetchPostsById(id) {
		return axios.get(`/api/retailer/posts/${id}`);
	},
	// 새글 저장
	registerPostsByBody(params) {
		return axios.post('/api/retailer/posts', params);
	},
	// 글 수정
	updatePostsByBody(params) {
		return axios.put(`/api/retailer/posts/${params.id}`, params);
	},
	// 글 삭제
	deletePostsByBody(params) {
		return axios.delete(`/api/retailer/posts/${params.id}`);
	},

	// // 댓글 조회
	// fetchSearchPostsComment(params) {
	// 	return axios.get('/api/retailer/posts/comment/search', { params });
	// },
	// 댓글 저장
	registerPostsCommentByBody(params) {
		return axios.post('/api/retailer/posts/comment', params);
	},
	// 댓글 수정
	updatePostsCommentByBody(params) {
		return axios.put(`/api/retailer/posts/comment/${params.id}`, params);
	},
	// 댓글 삭제
	deletePostsCommentByBody(params) {
		return axios.delete(`/api/retailer/posts/comment/${params.id}`);
	},

	// 파일 저장
	fileuploadMallImage(params) {
		return axios.post('/api/retailer/posts/file', params);
	},
});
