export default {
	SET_PARTNER_MALL_DATA(state, data) {
		// console.log('==SET_PARTNER_MALL_DATA(mutations)=>', data);
		state.partnerMallInfo = data;
	},
	// 찜한 상품 데이터
	SET_PICKITEM_DATA(state, data) {
		// console.log('server 에서 내려온 데이타(mutation) >> ', data)
		// console.log('==SET_PICKITEM_DATA(mutations)=>', data);
		// ES6 ... spread operator (전개연산자) : 배열의 원소들을 분해해서 개별요소 리턴
		state.productsPick.push(...data.data);
		state.pagePick = data.page.currentPage;
		state.totalPagesPick = data.page.totalPages;
		state.totalItemsPick = data.page.totalItems;
	},
	// 찜한 상품 삭제
	DELETE_PICKITEM: (state, pid) => {
		const idx = state.productsPick.findIndex(t => t.id === pid);
		if (idx !== -1) state.productsPick.splice(idx, 1);
	},
	// 찜한 상품 초기화
	SET_PICKITEM_INIT_DATA: (state, data) => {
		state.productsPick = [];
		state.pagePick = 0;
		state.totalPagesPick = 0;
		state.totalItemsPick = 0;
	},
	// 단골 매장 데이터
	SET_REGULARSTORE_DATA(state, data) {
		// console.log('server 에서 내려온 데이타(mutation) >> ', data)
		console.log('==SET_REGULARSTORE_DATA(mutations)=>', data);
		// ES6 ... spread operator (전개연산자) : 배열의 원소들을 분해해서 개별요소 리턴
		state.regularStores.push(...data.data);
		state.pageRegular = data.page.currentPage;
		state.totalPagesRegular = data.page.totalPages;
		state.totalItemsRegular = data.page.totalItems;
	},

	// 단골 매장 초기화
	SET_REGULARSTORE_INIT_DATA: (state, data) => {
		state.regularStores = [];
		state.pageRegular = 0;
		state.totalPagesRegular = 0;
		state.totalItemsRegular = 0;
	},

	// 상가 선택
	SET_MALLSTREET: (state, data) => {
		state.mallStreet = data;
	},

	// 상가별 도매상
	SET_SHOP_DATA: (state, data) => {
		// console.log('SET_SHOP_DATA 에서 내려온 데이타(mutation) >> ', data);
		state.streetSearchShops = data;
	},
};
