// CODE Vuex Actions

export default {
	// 코드 전체 조회 Actions
	async getAllCodes({ commit }, param) {
		// console.log('들어왔니?');
		try {
			const { data } = await this.$codeApi.fetchAllCodes(param);
			commit('SET_DATA', data);
		} catch (error) {}
	},

	// 코드 사용유무 수정 Actions(N-Y)
	async toggleActiveCode({ commit }, param) {
		try {
			const { id, active } = param;
			const requestData = {
				id,
				active,
			};
			const { data } = await this.$codeApi.toggleActiveCodeByBody(requestData);
			commit('ACTIVE_CODE', data);
		} catch (error) {}
	},

	// 코드 사용유무 수정 Actions(Y-N)
	async togglePositiveCode({ commit }, param) {
		try {
			const { id, active } = param;
			const requestData = {
				id,
				active,
			};
			const { data } = await this.$codeApi.toggleActiveCodeByBody(requestData);
			commit('POSITIVE_CODE', data);
		} catch (error) {}
	},

	// 코드 수정
	async updateCode({ commit }, param) {
		try {
			const { id, codeSn, codeName, codeDescription, codeLabels } = param;
			const requestData = {
				id,
				codeSn,
				codeName,
				codeDescription,
				codeLabels,
			};
			const { data } = await this.$codeApi.updateCodeByBody(requestData);
			commit('UPDATE_CODE', data);
		} catch (error) {}
	},

	// 코드 등록
	async addCode({ commit }, param) {
		try {
			const { codeSn, codeName, codeDescription, codeLabels } = param;
			const requestData = {
				codeSn,
				codeName,
				codeDescription,
				codeLabels,
			};
			const { data } = await this.$codeApi.registerCodeByBody(requestData);
			commit('ADD_CODE', data);
		} catch (error) {}
	},

	// 코드 삭제
	async deleteCode({ commit }, param) {
		try {
			const { id } = param;
			const requestData = {
				id,
			};
			await this.$codeApi.deleteCodeByBody(requestData);
			commit('DELETE_CODE', param);
		} catch (error) {}
	},
};
