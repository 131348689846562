import { deleteCookie } from '@/utils/cookies.js';

// User, Token 저장/삭제
export default {
	SET_USER(state, user) {
		state.user = user;
	},
	SET_TOKEN(state, token) {
		state.token = token;
	},
	SET_USERNAME(state, username) {
		state.username = username;
	},
	LOGOUT(state) {
		console.log('로그아웃 Mutations에 왔습니다.');
		state.user = null;
		state.token = null;
		deleteCookie('til_auth');
		deleteCookie('til_user');
	},
	SET_TERMS_AGREE(state, terms) {
		// console.log('checked value ?', terms);
		state.termsAgree = terms;
	},
	SET_POLICY_AGREE(state, policy) {
		// console.log('checked value ?', policy);
		state.policyAgree = policy;
	},
	SET_CONFIRM_AGREE(state, confirm) {
		// console.log('checked value ?', policy);
		state.confirmAgree = confirm;
	},
	SET_IS_REGISTRATED(state, isRegistrated) {
		state.isRegistrated = isRegistrated;
	},

	SET_CUSTOMERSHOP_DATA(state, data) {
		state.customerShop = data;
	},
	SET_COMMISSIONRATE(state, rate) {
		state.commissionRate = rate;
	},
	SET_DELIVERYCHARGE(state, charge) {
		state.deliveryCharge = charge;
	},
};
