export default {
	// Post 목록 Actions
	async getSearchPosts({ commit }, param) {
		try {
			const { data } = await this.$postApi.fetchSearchPosts(param);
			commit('SET_DATA', data);
		} catch (error) {}
	},
	// Post 상세보기
	async getPostById({ commit }, id) {
		try {
			const { data } = await this.$postApi.fetchPostsById(id);
			commit('SET_DETAIL_DATA', data);
		} catch (error) {}
	},

	// Post 등록
	async addPosts({ commit }, param) {
		try {
			await this.$postApi.registerPostsByBody(param);
		} catch (error) {}
		// console.log('actions-addPost==>', data);
		// commit('ADD_POST', data);
	},
	// Post 수정
	async updatePosts({ commit }, param) {
		try {
			await this.$postApi.updatePostsByBody(param);
		} catch (error) {}
	},
	// Post 삭제
	async deletePosts({ commit }, param) {
		try {
			await this.$postApi.deletePostsByBody(param);
		} catch (error) {}
	},

	// Post 댓글 등록
	async addPostsComment({ commit }, param) {
		try {
			await this.$postApi.registerPostsCommentByBody(param);
		} catch (error) {}
	},
	// Post 수정
	async updatePostsComment({ commit }, param) {
		try {
			// console.log('actions-updatePostsComment==>', param);
			await this.$postApi.updatePostsCommentByBody(param);
		} catch (error) {}
	},
	// Post 댓글 삭제
	async deletePostsComment({ commit }, param) {
		try {
			// console.log('actions-deletePostsComment==>', param);
			await this.$postApi.deletePostsCommentByBody(param);
		} catch (error) {}
	},
};
