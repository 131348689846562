export const cart = axios => ({
	// Cart 조회
	fetchSearchCarts(params) {
		return axios.get('/api/retailer/carts/search', { params });
	},
	// Cart 등록
	registerCartByBody(params) {
		return axios.post('/api/retailer/carts', params);
	},
	// Cart 수정
	updateCartByBody(params) {
		return axios.put(`/api/retailer/carts/${params.id}`, params);
	},
	// Cart N개 삭제
	deleteCartListByBody(cartList) {
		return axios.delete('/api/retailer/carts/cartids', { data: { cartList } });
	},
	// Cart 삭제
	deleteCartByBody(params) {
		return axios.delete(`/api/retailer/carts/${params.id}`);
	},
	// Cart 아이템 삭제
	deleteCartItemByBody(params) {
		return axios.delete(`/api/retailer/carts/cartItems/${params.id}`);
	},
});
