//
//
//
//
//
//
//
//
//
//
//
//

// import DefaultToolbar from '../toolbar/DefaultToolbar.vue';
import CsToolbar from '../toolbar/CsToolbar.vue';

export default {
	components: {
		// DefaultToolbar,
		CsToolbar,
	},
};
