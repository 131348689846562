//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations } from 'vuex';
import ProductDetailChild from '../products/ProductDetailChild.vue';
export default {
	components: {
		ProductDetailChild,
	},
	data() {
		return {
			offsetTop: 0,
			hidden: true,
		};
	},
	methods: {
		...mapMutations('app', ['SET_PRODUCTDETAIL_DIALOG_VISIBLE']),
		onScroll(e) {
			this.offsetTop = e.target.scrollTop;
			if (this.offsetTop >= 500) this.hidden = false;
			else this.hidden = true;
		},
		toTop() {
			const container = document.getElementById('scroll-target');
			const event = new CustomEvent('scroll', {});
			container.pageYOffset = 0;
			setTimeout(() => {
				container.scrollTop = 0;
			}, 200);
			container.dispatchEvent(event);
		},
		scrollBottom(captureHeight) {
			this.$refs.bottom.scrollIntoView({ behavior: 'smooth' });
			const scrollPosition = window.scrollY || document.documentElement.scrollTop;
			console.log(scrollPosition);
			if (captureHeight > scrollPosition) {
				setTimeout(() => {
					console.log('다시 아래로');
					this.$refs.bottom.scrollIntoView({ behavior: 'smooth' });
				}, 500);
			}
		},
		close() {
			this.toTop();
			this.$refs.child.reset();
			this.SET_PRODUCTDETAIL_DIALOG_VISIBLE(false);
		},
	},
};
