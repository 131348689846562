// CATEGORY Vuex Actions

export default {
	// Category 전체 조회 Actions
	async getAllCategory({ commit }, param) {
		try {
			const { data } = await this.$categoryApi.fetchListAndTreeCategory(param);
			commit('SET_DATA', data);
		} catch (error) {}
	},

	// Category Modal 조회 Actions
	async getModalCategory({ commit }, param) {
		try {
			const { data } = await this.$categoryApi.fetchTreeCategory(param);
			commit('SET_MODAL_DATA', data);
		} catch (error) {}
	},
	async getPartnerCategory({ commit }, param) {
		try {
			const partnerShopId = process.env.partnerShopId;
			const partnerResponse = await this.$productApi.fetchSearchCategory(partnerShopId);
			const shopCategory = partnerResponse.data;

			commit('SET_CATEGORY_DATA', shopCategory);
		} catch (error) {}
	},
	// Category Tree 조회 Actions
	async getTreeCategory({ commit }, param) {
		try {
			const { data } = await this.$categoryApi.fetchTreeCategory(param);
			const partnerShopId = process.env.partnerShopId;
			const partnerResponse = await this.$productApi.fetchSearchCategory(partnerShopId);
			const shopCategory = partnerResponse.data;
			console.log('shopCategory=', shopCategory);
			function filterCategoryPaths(treeData, desiredPaths) {
				if (Array.isArray(treeData.children)) {
					treeData.children = treeData.children.filter(child => {
						if (child.children) {
							filterCategoryPaths(child, desiredPaths);
							return true;
						} else if (desiredPaths.includes(child.categoryPath)) {
							console.log('===', child.categoryPath);
							return true;
						}
						return false;
					});
				}
			}
			filterCategoryPaths(data, shopCategory);
			console.log('data=', data);
			commit('SET_TREE_DATA', data);
		} catch (error) {}
	},
};
