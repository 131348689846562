export const shopMenu = [
	// {
	// 	title: '홈',
	// 	link: '/',
	// },
	{
		title: '전체상품',
		link: '/shop/AllProducts',
	},
	{
		title: '오늘의신상',
		link: '/shop/TodayProducts',
	},
	{
		title: '베스트신상',
		link: '/shop/BestProducts',
	},
	{
		title: 'SALE상품',
		link: '/shop/SaleProducts',
	},
	{
		title: '품절상품',
		link: '/shop/SoldoutProducts',
	},
	{
		title: '♡ 찜한상품',
		link: '/app/PickPage',
	},
	// {
	// 	title: '단골브랜드',
	// 	link: '/shop/RegularListPage',
	// },
];

export const myMenu = [
	{
		title: '장바구니',
		link: '/app/CartPage',
	},
	{
		title: '주문배송',
		link: '/app/OrderPage',
	},
	{
		title: '찜한상품',
		link: '/app/PickPage',
	},
	{
		title: '회원첨부파일',
		link: '/app/AttachPage',
	},
	{
		title: '회원정보수정',
		link: '/app/InfoPage',
	},
	{
		title: '회원탈퇴',
		link: '/app/DropPage',
	},
];
export const csMenu = [
	{
		title: '공지&이벤트',
		link: '/cs/NoticePage',
	},
	{
		title: 'Q&A',
		link: '/cs/QnaPage',
	},
];
export default {
	myMenu,
	shopMenu,
	csMenu,
};
