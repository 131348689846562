export const category = axios => ({
	// Category 전체 조회
	fetchAllCategory(params) {
		return axios.get('api/retailer/category/getAllCategory', { params });
	},

	// Category Tree 조회
	fetchTreeCategory(params) {
		return axios.get('api/retailer/category/rootCategory', { params });
	},

	// Category List and Tree 조회
	fetchListAndTreeCategory(params) {
		return axios.get('api/retailer/category/getTreeAndListCategory', { params });
	},
});
