import actions from './actions';
import mutations from './mutations';
import getters from './getters';

/*
|---------------------------------------------------------------------
| App Vuex Store
|---------------------------------------------------------------------
*/

const state = () => ({
	partnerMallInfo: {
		partnerMallId: 0,
		shopName: '쇼핑몰 제작중',
		partnerDomain: 'www.ddmmarket.co.kr',
		mallTitle: '쇼핑몰 제작중 입니다.',
		firstLogo: null,
		footerId: 0,
		president: '',
		mallManager: '',
		businessNumber: '',
		onlineBusinessNumber: '',
		onlineBusinessYn: 'Y',
		zipcode: '',
		street: '',
		houseNumber: '',
		bankName: '',
		accountNumber: '',
		accountHolder: '',
		email: '',
		tellNumber: '',
		mobileNumber1: '',
		mobileNumber2: '',
		openingTime1: '',
		openingTime2: '',
		closingTime1: '',
		closingTime2: '',
		notice1: '',
		notice2: '',
		mallLogoList: [],
		mallHeroList: [],
		mallPopupList: [],
		mallBannerList: [],
		partnerMallActiveClass: 'Y',
		footerActiveClass: 'Y',
	},
	// 찜한 상품
	productsPick: [],
	pagePick: 0,
	setPageSizePick: 12,
	totalPagesPick: 0,
	totalItemsPick: 0,
	// 단골 매장
	regularStores: [],
	pageRegular: 0,
	setPageSizeRegular: 12,
	totalPagesRegular: 0,
	totalItemsRegular: 0,
	// 상가별 도매상
	mallStreet: '',
	streetSearchShops: [],
});

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters,
};
