function saveAuthToCookie(value) {
	document.cookie = `til_auth=${value}; path=/;`;
}

function saveUserToCookie(value) {
	document.cookie = `til_user=${value}; path=/;`;
}

// 매장 주문 YN
function saveMallOrderYnToCookie(value) {
	document.cookie = `til_mallorder=${value}; path=/;`;
}
// 수수료율
function saveUserCommissionRateToCookie(value) {
	document.cookie = `til_commissionrate=${value}; path=/;`;
}
// 택배비
function saveUserDeliveryChargeToCookie(value) {
	document.cookie = `til_deliverycharge=${value}; path=/;`;
}
// 오늘만 보기
function saveTodayToCookie(value) {
	document.cookie = `til_today=${value}; path=/; max-age=86400;`;
}

function getAuthFromCookie() {
	return document.cookie.replace(/(?:(?:^|.*;\s*)til_auth\s*=\s*([^;]*).*$)|^.*$/, '$1');
}

function getUserFromCookie() {
	return document.cookie.replace(/(?:(?:^|.*;\s*)til_user\s*=\s*([^;]*).*$)|^.*$/, '$1');
}
// 오늘만 보기
function getTodayFromCookie() {
	return document.cookie.replace(/(?:(?:^|.*;\s*)til_today\s*=\s*([^;]*).*$)|^.*$/, '$1');
}
// 수수료율
function getUserCommissionRateFromCookie() {
	return document.cookie.replace(/(?:(?:^|.*;\s*)til_commissionrate\s*=\s*([^;]*).*$)|^.*$/, '$1');
}
// 택배비
function getUserDeliveryChargeFromCookie() {
	return document.cookie.replace(/(?:(?:^|.*;\s*)til_deliverycharge\s*=\s*([^;]*).*$)|^.*$/, '$1');
}
// 매장 주문 YN
function getMallOrderYnFromCookie() {
	return document.cookie.replace(/(?:(?:^|.*;\s*)til_mallorder\s*=\s*([^;]*).*$)|^.*$/, '$1');
}

// 쿠기 삭제
function deleteCookie(value) {
	document.cookie = `${value}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;`;
}

export {
	saveAuthToCookie,
	saveUserToCookie,
	getAuthFromCookie,
	getUserFromCookie,
	deleteCookie,
	saveTodayToCookie,
	getTodayFromCookie,
	saveUserCommissionRateToCookie,
	getUserCommissionRateFromCookie,
	saveUserDeliveryChargeToCookie,
	getUserDeliveryChargeFromCookie,
	saveMallOrderYnToCookie,
	getMallOrderYnFromCookie,
};
